import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect'



import gsap from 'gsap';
window.Alpine = Alpine;
import "./css/tailwindcss.scss";


import Swiper, { Navigation } from 'swiper/swiper-bundle.esm.js';
// import Swiper styles
import "swiper/swiper.scss";

document.addEventListener('alpine:init', () => {
    Alpine.data('scheme', () => ({
        howItWorks: null,
        init() {
            this.howItWorks = new Swiper('#section5', {
                slidesPerView: 'auto',
                resistanceRatio: 0,
                freeMode: {
                    enabled: true,
                    momentumBounceRatio: 0,
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });
        }
    }))

    Alpine.data('counter', (targetValue, decimals = 0) => ({
        targetValue: targetValue,
        value: 0,
        animatedValue: 0,
        init() {
            console.log("init counter");
        },
        start() {
            console.log("start counter");
            gsap.to(this, {
                duration: 0.5, animatedValue: targetValue,
                onUpdateParams: [this],
                onUpdate: function (obj) {
                    obj.value = obj.animatedValue.toLocaleString(undefined, { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
                }
            });
        },
    }))

    Alpine.data('animateTextAlongPath', (animationSpeed) => ({
        ofs:0,
        speed:animationSpeed,
        textLength:0,
        tick() { 
            this.$el.setAttribute('startOffset',this.ofs); 
            this.ofs+=1; 
            if (this.ofs>0) this.ofs=-this.textLength;  
        },
        init() {
            const curve = document.getElementById("text-curve-mobile");
            const displayObj = document.getElementById("display");
            this.textLength = this.$el.getComputedTextLength()/2;
            //this.textLength = this.$el.getBBox().width/2;
            //displayObj.innerHTML = curve.getTotalLength();
            var timerTick = this.tick.bind(this);
            setInterval(function() {
                timerTick();
              }, this.speed)
        },
    }))

    Alpine.data('scrollToAnchor', (sectionName) => ({
        name: null,
        init() {
            this.name = sectionName
        },
        doScroll() {
            const obj = document.getElementById(this.name);
            const scrollPos = obj.getBoundingClientRect().top + window.pageYOffset;
            window.scroll({
                top: scrollPos,
                left: 0,
                behavior: "smooth"
            });
            this.open = false;

        },
    }))

    Alpine.data('newsfeed', () => ({
        isLoading: false,
        news: null,
        init() {
            this.isLoading = true;
            fetch('https://wholesalemoscow.com/api/news/')
                .then(res => res.json())
                .then(data => {
                    this.isLoading = false;
                    this.news = data.news;
                    const swiper = new Swiper('.newsfeed', {
                        slidesPerView: 'auto',
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        },
                    });
                });

        }

    }))

    Alpine.data('contactForm', () => ({
        formData: {
            phone: '',
            email: '',
            message: ''
        },
        invalidMessage: false,
        invalidPhone: false,
        invalidEmail: false,
        state: 'idle',
        validateEmail: function (field) {
            const rgx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return rgx.test(field);
        },
        required: function (field) {
            return field.length
          },
        submitData() {
            let validForm=true;
            this.invalidEmail = (!(this.required(this.formData.email) && this.validateEmail(this.formData.email))) ;
            if (this.invalidEmail) validForm=false;
            this.invalidMessage = (!this.required(this.formData.message));
            if (this.invalidMessage) validForm=false;
            this.invalidPhone = (!this.required(this.formData.phone));
            if (this.invalidPhone) validForm=false;
            if (validForm)  {
                this.state='sending';
                fetch('https://wholesalemoscow.com/api/feedback/', {
                    method: 'POST',
                    body: JSON.stringify(this.formData)
                })
                    .then(res => res.json())
                    .then(data => {
                        this.state='success';
                        this.formData.message='';
                        this.formData.phone='';
                        this.formData.email='';
                    });
            }

        }

    }))

    Alpine.data('getPresentationForm', () => ({
        formData: {
            email: ''
        },
        invalidEmail: false,
        state: 'idle',
        validateEmail: function (field) {
            const rgx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return rgx.test(field);
        },
        required: function (field) {
            return field.length
          },
        submitData() {
            let validForm=true;
            this.invalidEmail = (!(this.required(this.formData.email) && this.validateEmail(this.formData.email))) ;
            if (this.invalidEmail) validForm=false;
            
            if (validForm)  {
                this.state='sending';
                fetch('https://wholesalemoscow.com/api/getpresentation/', {
                    method: 'POST',
                    body: JSON.stringify(this.formData)
                })
                    .then(res => res.json())
                    .then(data => {
                        this.state='success';
                        this.formData.email='';
                    });
            }

        }

    }))

    Alpine.data('section9swiper', () => ({
        swiper: null,
        init() {
            this.swiper = new Swiper('#section9 .swiper-container', {
                slidesPerView: 'auto',
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });


        }

    }))

})

Alpine.plugin(intersect);
Alpine.start();



import Plyr from 'plyr';
const player = new Plyr('#video1');
import "./css/plyr.css";
